/*
 * @Author: SongYijie
 * @Date: 2020-06-16 09:29:25
 * @LastEditors: SongYijie
 */ 
import wx from 'weixin-js-sdk';
import { getWxTicket, getWxPay } from '@api/wxJsSdk';
import { isWeChatBrowser } from './weChatAuthorization';
import { Toast } from 'mint-ui';

export default function wxPay(callback, payErrorCb) {
  const { location } = window;

  // 判断是不是在微信浏览器内
  if (!isWeChatBrowser) {
    Toast('请在微信中打开');
    return;
  }

  const link = location.href;

  getWxTicket({
    url: link
  }).then(({ code, data, message }) => {
    if (code === 200) {
      const { timestamp, nonceStr, signature } = data;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: 'wx0b6e7d6f8f4457d7', // 必填，公众号的唯一标识
        jsApiList: ['chooseWXPay'], // 必填，需要使用的JS-SDK接口列表
        timestamp, // 必填，生成签名的时间戳
        nonceStr, // 必填，生成签名的随机串
        signature // 必填，签名
      });

      wx.ready(() => {
        getWxPay().then(({code, data, message}) => {
          if (code === 200) {
            const { wx_nonceStr, wx_package, wx_paySign, wx_signType, wx_timeStamp } = data;
            wx.chooseWXPay({
              appId: 'wx0b6e7d6f8f4457d7',
              timestamp: wx_timeStamp, // 支付签名时间戳
              nonceStr: wx_nonceStr, // 支付签名随机串，不长于32 位
              package: wx_package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
              signType: wx_signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
              paySign: wx_paySign, // 支付签名
              success: () => {
                callback();
              },
              fail: () => {
                Toast('支付失败');
              },
              cancel: () => {
                //支付取消
                Toast('您已取消支付');
              }
            });
          } else if (code === 5024) {
            payErrorCb();
          } else {
            Toast(message);
          }
        })
      });
    } else {
      Toast(message)
    }
  });
}