/*
 * @Author: SongYijie
 * @Date: 2020-03-16 17:37:46
 * @LastEditors: SongYijie
*/
import service from '@utils/request';

// 获取微信票据
const getWxTicket = data => {
  return service({
    url: '/front/selfEE/wechatSign',
    method: 'post',
    data
  })
}

// 微信支付
const getWxPay = () => {
  return service({
    url: '/front/selfEE/wechatPay',
    method: 'post',
    data: {}
  })
}

export {
  getWxTicket,
  getWxPay
}