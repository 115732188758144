<template>
  <div class="confirm-info">
    <p class="remind-info">请您认真阅读以下信息，无误后请确认</p>
    <!-- <div class="box-wrap">
      <p class="title">注册信息</p>
      <div class="content">
        <p>字号名称：<span>(无字号)</span></p>
        <p>经营范围：<span>商贸服务</span></p>
        <p>组织形式：<span>个人经营备份</span></p>
        <p>从业人数：<span>1人</span></p>
        <p>注册资本：<span>2000元</span></p>
        <p>执照副本：<span>无</span></p>
      </div>
    </div> -->
    <div class="box-wrap">
      <p class="title">经营者信息</p>
      <div class="content">
        <p>姓 名：<span>{{confirmInfo.applyUserName}}</span></p>
        <p>证件类型：<span>身份证</span></p>
        <p>证件号码：<span>{{confirmInfo.applyUserIdNum}}</span></p>
        <p>移动电话：<span>{{confirmInfo.userMobile}}</span></p>
        <p>地 址：<span>{{confirmInfo.userAddress}}</span></p>
        <p v-if="confirmInfo.payState === 1 && confirmInfo.collAmt && confirmInfo.collAmt > 0">支付金额：<span class="pay-amt">¥{{confirmInfo.collAmt || 0}}</span></p>
      </div>
    </div>
    <div class="box-wrap">
      <p class="title">经办人信息</p>
      <div class="content">
        <p>姓 名：<span>黄戍军</span></p>
        <p>证件类型：<span>身份证</span></p>
        <p>证件号码：<span>330327********0038</span></p>
        <p>移动电话：<span>135****177</span></p>
      </div>
    </div>
    <div class='footer-bt'>
      <mt-button type='danger' size='large' @click.native='throttleHandleNext'>
        {{confirmInfo.payState === 1 && confirmInfo.collAmt && confirmInfo.collAmt > 0 ? '去付款' : '去签约'}}
      </mt-button>
    </div>
  </div>
</template>

<script>
import { throttle } from "@utils/common";
import { getConfirmInfo, confirmInfo } from "@api/individualBusiness";
import { Toast } from 'mint-ui';
import { isWeChatBrowser } from '@utils/weChatAuthorization';
import wxPay from '@utils/wxPay';
export default {
  name: "confirmInfo",
  data() {
    return {
      confirmInfo: {},
      isWeChatBrowser: false
    };
  },
  components: {
  },
  mounted() {
    this.isWeChatBrowser = isWeChatBrowser();
    this.handleGetConfirmInfo();
  },
  beforeDestroy() {},
  computed: {},
  methods: {
    handleGetConfirmInfo() {
      getConfirmInfo().then(res => {
        if (res.code === 200) {
          this.confirmInfo = res.data;
        } else {
          Toast(res.message);
        }
      })
    },
    throttleHandleNext: throttle(
      function() {
        this.handleNext();
      }, 5000
    ),
    handleNext() {
      const { collAmt, payState } = this.confirmInfo;
      if (payState === 1 && collAmt && collAmt > 0 ) {
        if (!this.isWeChatBrowser) {
          Toast('请在微信中打开,以完成支付');
          return;
        }
        wxPay(() => this.handleConfirmInfo(true), () => this.handleGetConfirmInfo());
      } else {
        this.handleConfirmInfo(false);
      }
    },
    handleConfirmInfo(isPay) {
      isPay && Toast('支付成功');
      confirmInfo().then(res => {
        if (res.code === 200) {
          this.$router.replace('/individual-business/protocol');
        } else {
          Toast(res.message);
        }
      })
    }
  }
};
</script>

<style lang="less" scoped>
*:not(.footer-bt) {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.confirm-info {
  padding: 4.267vw 4.8vw 110px;
}
.remind-info {
  height: 5.6vw;
  font-size: 4vw;
  color: rgba(153, 153, 153, 0.6);
  line-height: 5.6vw;
  margin-bottom: 2.8vw;
}
.box-wrap {
  width: 100%;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 1.8667vw;
  padding: 0 3.0667vw 3.0667vw;
  margin-bottom: 5.0667vw;
}
.title {
  height: 10.6667vw;
  font-size: 3.7333vw;
  color: rgba(102, 102, 102, 1);
  line-height: 10.6667vw;
  padding-left: 3vw;
  position: relative;
  border-bottom: 0.2667vw solid #e6e6e6;
  &::before {
    content: '';
    width: 0.8vw;
    height: 4.5333vw;
    background-color: #C92E26;
    position: absolute;
    top: calc(50% - 2.2vw);
    left: 0;
  }
  margin-bottom: 1.0667vw;
  overflow: hide;
}
.content {
  font-size: 3.7333vw;
  color: rgba(153, 153, 153, 0.8);
  line-height: 4.8vw;
  padding-top: 1.0667vw;
  p {
    margin-bottom: 2vw;
  }
  span {
    color: #666666;
  }
  .pay-amt {
    color: #C1272D;
    font-size: 5vw;
  }
}
</style>