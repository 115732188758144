/*
 * @Author: SongYijie
 * @Date: 2020-04-09 09:51:18
 * @LastEditors: SongYijie
 */

import { loginByWXCode } from "@api/login";
import {getParams} from './common'

/**
 * 判断是否在正式环境的微信中
 */
export function isFormalEnvAndWeChatBrowser() {
  const ua = window.navigator.userAgent.toLowerCase();
  const { origin } = window.location;
  const isInWeixin = ua.indexOf("micromessenger") !== -1;
  const isFormalEnv =
    origin.indexOf("https://m.xiydr.cn") !== -1 ||
    origin.indexOf("https://h5.xiyk.cn") !== -1;
  return isInWeixin && isFormalEnv;
}

/**
 * 判断是否在微信中
 */
export function isWeChatBrowser() {
  const ua = window.navigator.userAgent.toLowerCase();
  const isInWeixin = ua.indexOf("micromessenger") !== -1;
  return isInWeixin;
}

/**
 * 自动跳转微信授权地址
 * @param {String} currentUrl 活动地址
 * @param {Number} type 1: 静默授权：2：非静默授权
 */
export function weChatAuthorization(type) {
  const urlData = getParams(window.location.href);
  let redirecturi = window.location.origin
  if (urlData.comer) {
    redirecturi = window.location.origin + "/UploadVideo/index";
  }else{
    redirecturi = window.location.origin + "/login";
  }

  let scope = "";
  if (Number(type) === 1) {
    // 静默授权
    scope = "snsapi_base";
  } else if (Number(type) === 2) {
    // 非静默授权
    scope = "snsapi_userinfo";
  }

  redirecturi = encodeURIComponent(redirecturi);
  let weChatAuthorizationUrl =
    "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx0b6e7d6f8f4457d7&redirect_uri=" +
    redirecturi +
    "&response_type=code&scope=" +
    scope +
    "&state=#wechat_redirect";

    console.log('replace',weChatAuthorizationUrl)
  window.location.replace(weChatAuthorizationUrl);
}

/**
 * 去掉指定链接中的参数
 * @param {String} name 要去掉的参数名称
 * @param {String} url 链接
 */
export function deleteUrlPara(name, url) {
  let turl = "",
    rurl = url.split("#")[0];
  if (rurl.indexOf("?") === -1) {
    return url;
  }
  let arr_url = rurl.split("?");
  if (arr_url[1].indexOf(name) === -1) {
    return url;
  }
  let base = arr_url[0];
  let arr_param = arr_url[1].split("&");
  let index = -1;
  for (let i = 0; i < arr_param.length; i++) {
    let paired = arr_param[i].split("=");
    if (paired[0] === name) {
      index = i;
      break;
    }
  }
  if (index === -1) {
    return url;
  }
  arr_param.splice(index, 1);
  if (arr_param.length > 0) {
    if (url.split("#")[1]) {
      turl = base + "?" + arr_param.join("&") + "#" + url.split("#")[1];
    } else {
      turl = base + "?" + arr_param.join("&");
    }
  } else if (arr_param.length === 0) {
    if (url.split("#")[1]) {
      turl = base + "#" + url.split("#")[1];
    } else {
      turl = base;
    }
  }
  return turl;
}

/**
 * 将参数加入到url中
 * @param {String} name 参数名称
 * @param {String} value 参数值
 * @param {String} url 插入的url
 */
export function addUrlPara(name, value, url) {
  let currentUrl = url.split("#")[0],
    gotoUrl = "";
  if (/\?/g.test(currentUrl)) {
    let re = new RegExp(name, "g");
    if (!re.test(currentUrl)) {
      currentUrl += "&" + name + "=" + value;
    } else {
      let arr_url = currentUrl.split("?");
      let base = arr_url[0];
      let arr_param = arr_url[1].split("&");
      for (let i = 0; i < arr_param.length; i++) {
        let paired = arr_param[i].split("=");
        if (paired[0] === name) {
          paired[1] = value;
          arr_param[i] = paired.join("=");
          break;
        }
      }
      currentUrl = base + "?" + arr_param.join("&");
    }
  } else {
    currentUrl += "?" + name + "=" + value;
  }
  if (url.split("#")[1]) {
    gotoUrl = currentUrl + "#" + url.split("#")[1];
  } else {
    gotoUrl = currentUrl;
  }
  return gotoUrl;
}

/**
 * 查看连接中参数的值
 * @param {String} url 链接地址
 * @param {Sting} ref 要查找的参数名称
 */
export function getUrlParam(url, ref) {
  let turl = "";
  if (url.indexOf("#") > -1) {
    turl = url.split("#")[0];
  } else {
    turl = url;
  }

  let str = "";

  // 如果不包括此参数
  if (turl.indexOf("?") === -1) {
    return null;
  }

  str = turl.split("?")[1];

  if (str.indexOf(ref) === -1) return null;

  let arr = str.split("&");
  for (let i in arr) {
    let paired = arr[i].split("=");

    if (paired[0] === ref) {
      return paired[1];
    }
  }

  return null;
}

/**
 * 根据微信code获取相关信息
 * @param {String} code 微信授权code
 * @param {String} currentUrl 页面地址
 */
export function getWXInfoByCode(code) {
  return new Promise(resolve => {
    loginByWXCode({ code }).then(res => {
      const { code, data } = res;
      if (code === 2104) {
        weChatAuthorization(1);
      } else if (code === 2105) {
        weChatAuthorization(2);
      } else if (code === 200) {
        resolve(data);
      }
    });
  });
}

/**
 * 判断相关信息是否存在
 */
export function getWeChatAuthorization() {
  return new Promise(resolve => {
    if (!isFormalEnvAndWeChatBrowser()) {
      resolve();
      return;
    }
    const currentUrl = window.location.href;
    const code = getUrlParam(currentUrl, "code");
    console.log(code,'code')
    if (code !== null) {
      resolve(getWXInfoByCode(code));
    } else {
      weChatAuthorization(1);
    }
  });
}
